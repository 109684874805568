import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef, CSSProperties } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {CheckToken} from '../functions/api';
import SPSLoader from './SPSLoader';
import SettingMail from './settings/settingMail';
import SettingCGU from './settings/settingCGU';



function Settings(props) {

  const [stateWindowEdit, setStateWindowEdit] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [typeUser, setTypeUser] = useState(0);
  const [settingDisplayed, setSettingDisplayed] = useState("");

  let [loading, setLoading] = useState(false);
  
  
  useEffect(() => {
    if(stateWindowEdit == false)
    {
      
    }
    //else
    //  setUpdateNotSaved(false);
    setLoading(false);
  }, [stateWindowEdit]);

 
 
  const handleCancel= (event) => {
    setSettingDisplayed("");
  };
  
  const closeSetting = () => {
    setSettingDisplayed("");
  }
  
  const handleBack = (event) => {
    
    props.screenHandling(0);
  };

  useEffect(() => {
    if(firstLoad==false)
    {
      CheckToken(onCheckToken);
      setFirstLoad(true);
    }
  }, [firstLoad]);

  const onCheckToken = (ok, type) => {
    if(ok>0)
      setTypeUser(type);
    //else  // si le token n'est pas bon on se deconnecte
    //  Logout(afterLogout);
    
  }

  function titre_pref()
  {
    var retour = "Réglages";
    switch(settingDisplayed)
    {
      case "Mail":
        retour = "Mail mot de passe utilisateur";
        break;
      case "CGU":
        retour = "Conditions générales d'utilisation";
        break;
    }

    return retour;
  }

  return (
    <div className={props.screenVisible?"h-100  animscreen visible":"h-100 animscreen hidden"} id="administrateurs_screen">
       {settingDisplayed == ""?
      <div className={stateWindowEdit==false?"liste containerxl":"liste containerxl hidden"} >
          
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6"><h1 className="h4 py-3">Réglages</h1></div>
              <div className="col-3 text-end py-2"></div>
            </div>
          
          <SPSLoader load={loading} />
          <div className="content-screen">
            
            <table className="table">
              <tbody>
                <tr>
                  <td className="px-0">
                    <ul className="container liste-data">
                      <li className="row mb-2 alternate-color" >
                          <div className="col-12" onClick={() => setSettingDisplayed('Mail')}>
                            <strong>Mail de définition de mot de passe</strong>
                          </div>
                      </li>
                      {typeUser=='ADMIN' && 
                      <li className="row mb-2 alternate-color" >
                          <div className="col-12" onClick={() => setSettingDisplayed('CGU')}>
                            <strong>Conditions générales d'utilisation</strong>
                          </div>
                      </li>}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
            
          
          
    </div>:
    <div className="saisie containerxl">
      <SPSLoader load={loading} />
     
      <div className="row headScreen">
        <div className="col-3 text-start py-2"><button className="btn btn-link" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
        <div className="col-6"><h1 className="h4 py-3">{titre_pref()}</h1></div>
        <div className="col-3"></div>
      </div>
      
      <div className="container py-3">
        
        {settingDisplayed == "Mail" && <SettingMail onClose={closeSetting}/>}
        {settingDisplayed == "CGU" && <SettingCGU onClose={closeSetting}/>}
        
      </div>
    </div>}
    </div>
  );

  
}

export default Settings;
