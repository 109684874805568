import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate  } from 'react-router-dom';
import { LostPWD, UserLogin } from "../functions/api";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LogoVigivel from '../resources/images/LogoVigivel2024.png'

const Login = (props) => { 
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [emailLost, setEmailLost] = useState();
  const [mode, setMode] = useState("");
  const [lostStatus, setLostStatus] = useState(0);
  const handleLogin = (event) => {
    event.preventDefault();
    UserLogin(login, password, props.screenHandling);
    /*axios.post(process.env.REACT_APP_USER_API_BASE_URL + "auth/login", { login: login, password: password }).then(res => {
      localStorage.setItem('userAuth', JSON.stringify(res.data));
      setIsRedirect(true);
    })*/
    
  };

  const handleLostPWD = (event) => {
    event.preventDefault();
    LostPWD(emailLost, afterMailLost);
  }

  const [isRedirect, setIsRedirect] = useState(false);
  
  useEffect(() => {
    //redirectToHome();
  }, [isRedirect]);
  
  const afterMailLost = (retour) => {
    if(retour)
      setLostStatus(1);
    else
    setLostStatus(-1);
  }
  
  const loginOk = () => {
    setIsRedirect(true);
    props.screenHandling(0);
  };

  return (
    <div className="h-100 d-flex align-items-center">
            <div className="login-form w-100 m-auto">
                <img src={LogoVigivel} className="mw-100"/>
                <h2 className="text-center">Administration</h2>
                {mode == "LOSTPWD"?
                <Form className="mt-3" onSubmit={handleLostPWD}>
                  <Form.Group className="form-floating mb-3" controlId="loginEMail">
                      <Form.Control type="email" placeholder="Adresse email" value={emailLost} onChange={e => { setEmailLost(e.target.value); }} disabled={lostStatus>0?true:false} />
                      <Form.Label>Adresse email</Form.Label>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100" disabled={lostStatus>0?true:false}>
                      Envoyer
                  </Button>
                  {lostStatus == 1 && 
                  <div className="alert alert-success mt-4 text-center">Un email à été envoyé à l'adresse {emailLost}.<br />Veuillez suivre les instructions de celui-ci pour définir votre mot de passe</div>
                  }
                   {lostStatus == -1 && 
                  <div className="alert alert-danger mt-4 text-center">Impossible de trouver l'utilisateur.<br/>Veuilez vérifier l'email saisi.</div>
                  }
                  <div className="text-center">
                      <button className="btn btn-link" onClick={(event) => {setMode("")}}>Identification</button>
                    </div>
                </Form>
                :
                <Form className="mt-3" onSubmit={handleLogin}>
                    <Form.Group className="form-floating mb-3" controlId="loginEMail">
                        <Form.Control type="email" placeholder="Adresse email" value={login} onChange={e => { setLogin(e.target.value); }} />
                        <Form.Label>Adresse email</Form.Label>
                    </Form.Group>

                    <Form.Group className="form-floating mb-3" controlId="loginPassword">
                        <Form.Control type="password" placeholder="Mot de passe" value={password} onChange={e => { setPassword(e.target.value); }}/>
                        <Form.Label>Mot de passe</Form.Label>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">
                        Valider
                    </Button>
                    <div className="text-center">
                      <button className="btn btn-link" onClick={(event) => {setMode("LOSTPWD")}}>Mot de passe oublié</button>
                    </div>
                </Form>
                }
            </div>
        </div>
    
  );
}



export function LoadScreen(page)
{
  //Navigate(page);
}

export default Login;
