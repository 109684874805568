import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, CSSProperties } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {GetDevices, GetDevice, DeleteApi, SaveDevice, CheckToken} from '../functions/api';
import SPSLoader from './SPSLoader';



function Capteurs(props) {

  const [stateWindowEdit, setStateWindowEdit] = useState(false);
  const [datas, SetDatas] = useState([]);
  const [theData, SetTheData] = useState([]);
  
  const [uniqueId, setUniqueId] = useState(0);
  const [app_eui, setAppEui] = useState("");
  const [dev_eui, setDevEui] = useState("");
  const [app_key, setAppKey] = useState("");
  const [dev_addr, setDevAddr] = useState("");
  const [crm_device_id, setCrmDeviceId] = useState("");
  
  const [filterSearch, setFilterSearch] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [typeUser, setTypeUser] = useState('');
  
  let [loading, setLoading] = useState(false);
  
  
  useEffect(() => {
    if(stateWindowEdit == false)
    {
      console.log("recherche des datas");
      GetDevices(filterSearch, SetDatas);
    }
    //else
    //  setUpdateNotSaved(false);
    setLoading(false);
  }, [stateWindowEdit]);

  useEffect(() => {
    console.log(JSON.stringify(theData[0]));
    if(theData.length > 0 && theData[0].id != undefined)
    {
      const data = theData[0];
      setLoading(true);
      setUniqueId(data.id);
      setAppEui(data.app_eui?data.app_eui:'');
      setDevEui(data.dev_eui?data.dev_eui:"");
      setAppKey(data.app_key?data.app_key:"");
      setDevAddr(data.dev_addr?data.dev_addr:"");
      setCrmDeviceId(data.crm_device_id?data.crm_device_id:"")
      setStateWindowEdit(true);
      setLoading(false);
    }
  }, [theData]);

 

  useEffect(() => {
    GetDevices(filterSearch, SetDatas);
  }, [filterSearch]);
  
  const handleDeleteData = (id) => {
    confirmAlert({
      title: 'Suppression',
      message: 'Souhaitez-vous supprimer cet capteur ?',
      buttons: [
        {
          label: 'Oui',
          onClick: () => {
            DeleteApi('device', id, afterDelete);
          }
        },
        {
          label: 'Non',
          onClick: () => { 

          }
        }
      ]
    });
  };

  const afterDelete = () => {
    GetDevices(filterSearch, SetDatas);
  };

  const handleAdd = (event) => {
    clearFields();
    
    setStateWindowEdit(true);
  };

  const clearFields = () => {
    setUniqueId(0);
    setAppEui('');
    setDevEui("");
    setAppKey("");
    setDevAddr("");
    setCrmDeviceId("");
  }

  const handleCancel= (event) => {
    clearFields();
    setStateWindowEdit(false);
  };
  const handleClickEdit = (id) => {
    setLoading(true);
    GetDevice(id, SetTheData);
  };

  
  const handleSubmit = (event) => {
    if(typeUser == 'ADMIN')
    {
      event.preventDefault();
      
      setLoading(true);
      const body = makeobject();
      SaveDevice (body, afterSubmit);
    }
    else
      setStateWindowEdit(false);
  };

  const afterSubmit = () => {
    clearFields();
    setStateWindowEdit(false);
  }

  function makeobject()
  {
    const body = {
      id:uniqueId,
      dev_eui: dev_eui,
      app_eui: app_eui,
      app_key: app_key,
      dev_addr: dev_addr,
      crm_device_id: crm_device_id
      
    };
    return body;
  }
    
  const handleBack = (event) => {
    
    props.screenHandling(0);
  };

  useEffect(() => {
    if(firstLoad==false)
    {
      CheckToken(onCheckToken);
      setFirstLoad(true);
    }
  }, [firstLoad]);

  const onCheckToken = (ok, type) => {
    if(ok>0)
      setTypeUser(type);
    //else  // si le token n'est pas bon on se deconnecte
    //  Logout(afterLogout);
    
  }

  return (
    <div className={props.screenVisible?"animscreen visible":"animscreen hidden"} id="administrateurs_screen">
      <div className={stateWindowEdit==false?"liste containerxl":"liste containerxl hidden"} >
          
            <div className="row headScreen">
              <div className="col-3 text-start py-2"><button className="btn btn-link" onClick={handleBack}><FontAwesomeIcon icon={faChevronLeft} /> Retour</button></div>
              <div className="col-6"><h1 className="h4 py-3">Capteurs</h1></div>
              <div className="col-3 text-end py-2">{typeUser == 'ADMIN'?<button className="btn btn-link" onClick={handleAdd}><FontAwesomeIcon icon={faPlus} /></button>:<div></div>}</div>
            </div>
          
          <SPSLoader load={loading} />
          <div className="content-screen">
            <div className="container">
              <div className="row">
                <div className="col-md-10 text-center my-2">
                  <input type="text" className="form-control" placeholder='Recherche...' onChange={e => { setFilterSearch(e.target.value); } } value={filterSearch} />
                </div>
                <div className="col-md-2 text-center my-2">
                  {datas.length} enregistrement{datas.length > 1?'s':''}
                </div>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="px-0">
                    {datas.length > 0?
                    <ul className="container liste-data">
                      {datas.map((r) =>(
                      <li className="row mb-2 alternate-color" key={r.id}  >
                          <div className="col-8" onClick={() => handleClickEdit(r.id)}>
                            <strong>{r.crm_device_id} / {r.dev_eui}</strong><br />
                            {r.app_key} {r.dev_addr} 
                          </div>
                          <div className="col-2" onClick={() => handleClickEdit(r.id)}>
                            <strong>{r.ferme}</strong>
                          </div>
                          <div className="col-2 text-end col-action">
                              <div className="tools-actions">
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDeleteData(r.id)}><FontAwesomeIcon icon={faTrashCan} /></button>
                              </div>
                          </div>
                      </li>
                      ))}
                    </ul>:<div className="text-muted text-center">Aucun enregistrement</div>
                    }
                    {typeUser == 'ADMIN'?
                    <div className="text-center"><button type="button" className="btn btn-primary" onClick={handleAdd}>Créer un capteur</button></div>
                    :<span></span>}
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
            
          
          
    </div>
    <div className={stateWindowEdit?"saisie containerxl":"saisie containerxl hidden"}>
      <div className="row headScreen">
        <div className="col-3 text-start py-2"><button className="btn btn-link" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faChevronLeft} /> Annuler</button></div>
        <div className="col-6"><h1 className="h4 py-3">Capteur</h1></div>
        <div className="col-3"></div>
      </div>
      <SPSLoader load={loading} />
      <div className="container py-3">
        <form onSubmit={handleSubmit}>
          <div className="row">
          <div className="col-md-6">
              <input type="hidden" name="id" value={uniqueId} />
              <div className="form-group mb-3 row">
                <label className="col-md-2">Dev EUI</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="DevEUI" value={dev_eui} onChange={e => { setDevEui(e.target.value); }} disabled={typeUser != 'ADMIN'?true:false}/>
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">App Eui</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="AppEUI" value={app_eui} onChange={e => { setAppEui(e.target.value); }}  disabled={typeUser != 'ADMIN'?true:false}/>
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">App Key</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="AppKey" value={app_key} onChange={e => { setAppKey(e.target.value); }}  disabled={typeUser != 'ADMIN'?true:false}/>
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Dev Addr</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="DevAddr" value={dev_addr} onChange={e => { setDevAddr(e.target.value); }}  disabled={typeUser != 'ADMIN'?true:false}/>
                </div>
              </div>
              <div className="form-group mb-3 row">
                <label className="col-md-2">Réf. interne</label>
                <div className="col-md-10">
                  <input type="text" className="form-control" name="CRMDeviceId" value={crm_device_id} onChange={e => { setCrmDeviceId(e.target.value); }} />
                </div>
              </div>
              
              
              
              
            </div>
            
            
          </div>
          <div className="text-end mt-3 p-3 border-top">
            <button className="btn btn-primary" type="submit">Valider</button>
          </div>
        </form>
        
      </div>
    </div>
    </div>
  );

  
}

export default Capteurs;
