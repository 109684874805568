import { useEffect, useState } from "react";
import Login from "./Login";
import { AuthToken, Logout, CheckToken, ValidLostPwd } from "../functions/api";
import { checkPassword } from "../functions/tools";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LogoVigivel from '../resources/images/LogoVigivel2024.png'


function InitPWD(props) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")
    const [errorMessage, setErrorMessage] = useState("");

    const handleValidPwd = (event) => {
        event.preventDefault();
        var message = "";
        if(email == "")
            message = "Email obligatoire";
        if(password != confirmPassword)
            message = "Les mots de passe doivent être identiques";
        if(message == "")
        {
            message = checkPassword(password);
        }

        if(message != "")
            setErrorMessage(message);
        else
        {
            setErrorMessage("");
            // on envoie au serveur pour validation
            ValidLostPwd(email, password, token, afterValid);
        }
    }

    const afterValid = (valid) => {
        if(valid)
            setUpdateSuccess(true);
        else
        {
            setErrorMessage("La modification du mot de passe a échoué. Le lien de définition de mot de passe est certainement obsolète.")
        }
    }

    const handleIdentifiaction = () => {
        document.location.href= "/";
    }

        return (
            <div className="h-100 d-flex align-items-center">
            <div className="login-form w-100 m-auto">
                <img src={LogoVigivel} className="mw-100"/>
                <h2 className="text-center">Nouveau<br/>mot de passe</h2>
                {updateSuccess==false? 
                <div>
                {token=="" && 
                <div className="alert alert-danger">Erreur d'appel. Merci de réessayer.</div>}
                <Form className="mt-3" onSubmit={handleValidPwd}>
                    <Form.Group className="form-floating mb-3" controlId="loginEMail">
                        <Form.Control type="email" placeholder="Adresse email" value={email} onChange={e => { setEmail(e.target.value); }} required/>
                        <Form.Label>Adresse email</Form.Label>
                    </Form.Group>

                    <Form.Group className="form-floating mb-3" controlId="loginPassword">
                        <Form.Control type="password" placeholder="Mot de passe" value={password} onChange={e => { setPassword(e.target.value); }} required/>
                        <Form.Label>Mot de passe</Form.Label>
                    </Form.Group>
                    <Form.Group className="form-floating mb-3" controlId="confirmPassword">
                        <Form.Control type="password" placeholder="Confirmation mot de passe" value={confirmPassword} onChange={e => { setConfirmPassword(e.target.value); }} required/>
                        <Form.Label>Confirmez le mot de passe</Form.Label>
                    </Form.Group>
                    {errorMessage != "" && 
                    <div className="alert alert-danger text-center">
                        {errorMessage}
                    </div>}
                    <Button variant="primary" type="submit" className="w-100">
                        Valider
                    </Button>
                    
                </Form>
                </div>
                :
                <div className="alert alert-success text-center">
                    Votre mot de passe a été modifié avec succès.
                    <Button variant="primary" type="button" className="w-100" onClick={() => {handleIdentifiaction()}}>
                        Identification
                    </Button>
                </div>
                }
            </div>
        </div>
        );
    
}

export default InitPWD;
