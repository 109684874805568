
//import fr_FR from './langs/fr_FR.js';

export const initConfig = {
    /*language_url: './langs/',
    language: 'fr_FR',*/
    height: 300,
    menubar: true,
    plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
    'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'help', 'wordcount'
    ],
    toolbar: 'formatselect | ' +
    'bold italic underline backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
    'removeformat | help',

}

export const simpleConfig = {
    /*language_url: './langs/',
    language: 'fr_FR',*/
    height: 300,
    menubar: false,
    plugins: [
    'lists', 'link', 'table'
    ],
    toolbar: 'formatselect | ' +
    'bold italic underline backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol |' +
    'removeformat | help',

}