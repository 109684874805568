import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef, CSSProperties } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {GetDevices, GetDevice, DeleteApi, SaveDevice, CheckToken, GetSettingMail, SaveSettingMail} from '../../functions/api';
import SPSLoader from '../SPSLoader';
import {Editor} from '@tinymce/tinymce-react';
import {initConfig} from '../../functions/initConfig';



function SettingMail(props) {

  const editorRef = useRef(null);
  const [stateWindowEdit, setStateWindowEdit] = useState(false);
  const [mailEdit, SetMailEdit] = useState({ref:"", object:"", content:""});
  const [firstLoad, setFirstLoad] = useState(false);
  const [initMailContent, setInitMailContent] = useState("");

  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if(firstLoad==false)
    {
      GetSettingMail('MAIL_PW', SetTheData);
      setFirstLoad(true);
    }
  }, [firstLoad]);
  
  const SetTheData = (data) => {
    console.log(JSON.stringify(data));
    setInitMailContent(data.Content);
    SetMailEdit({ref: data.Ref, object: data.Object, content: data.Content});
  };
  
  const handleChangeContent = (value, editor) => {
    SetMailEdit({...mailEdit, content:value});
  };
  
  const handleSubmit = (event) => {
    
    event.preventDefault();
    
    setLoading(true);
    const body = {Ref: mailEdit.ref, Content: mailEdit.content, Object: mailEdit.object};
    SaveSettingMail (body, afterSubmit);
    console.log("submit");
  };

  const afterSubmit = () => {
    props.onClose();
  }

  return (<form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 mb-4">
                <label>Objet</label>
                <input type="text" className="form-control" name="Objet" value={mailEdit.object} onChange={e => { SetMailEdit({...mailEdit, object:e.target.value}); }} />
              </div>
              <div className="col-12">
                <label>Mail</label>
                  <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={initMailContent}
                    tinymceScriptSrc='/scripts/tinymce/tinymce.min.js'
                    init={initConfig}
                    onEditorChange={handleChangeContent}
                  />
                  
              </div>
            </div>
            <div className="text-end mt-3 p-3 border-top">
              <button className="btn btn-primary" type="submit">Valider</button>
            </div>
          </form>);
}

export default SettingMail;