export function datetime_to_fr(datetime)
{
    var date = new Date(datetime);
    var retour = {};

    //retour.date = date.getDate().toFixed(2)+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
    retour.date = date.toLocaleDateString('fr-FR');
    retour.hour = date.getHours()+":"+date.getMinutes();

    return retour;
}

export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
}

export function getCurrentTime(separator=''){

    let newDate = new Date()
    let hour = newDate.getHours();
    let minutes = newDate.getMinutes();
    
    return `${hour<10?`0${hour}`:`${hour}`}${separator}${minutes<10?`0${minutes}`:`${minutes}`}`;
}

export function checkPassword(password)
{
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;
    const uppercasePassword =   uppercaseRegExp.test(password);
    const lowercasePassword =   lowercaseRegExp.test(password);
    const digitsPassword =      digitsRegExp.test(password);
    const specialCharPassword = specialCharRegExp.test(password);
    const minLengthPassword =   minLengthRegExp.test(password);
    let errMsg ="";
    if(password.length===0){
            errMsg="Mot de passe vide";
    }else if(!uppercasePassword){
            errMsg="Une majuscule minimum";
    }else if(!lowercasePassword){
            errMsg="Une minuscule minimum";
    }else if(!digitsPassword){
            errMsg="Au minimum un chiffre";
    }else if(!specialCharPassword){
            errMsg="Au moins un caractère special";
    }else if(!minLengthPassword){
            errMsg="8 caractères minimum";
    }else{
        errMsg="";
    }

    return errMsg;
}