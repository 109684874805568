import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faChevronLeft, faXmark, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useRef, CSSProperties } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import {GetSetting, SaveSetting} from '../../functions/api';
import SPSLoader from '../SPSLoader';
import {Editor} from '@tinymce/tinymce-react';
import {initConfig} from '../../functions/initConfig';



function SettingCGU(props) {

  const editorRef = useRef(null);
  const [cguEdit, SetCguEdit] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [initTextContent, setInitTextContent] = useState("");

  let [loading, setLoading] = useState(false);

  useEffect(() => {
    if(firstLoad==false)
    {
      GetSetting('CGU', SetTheData);
      setFirstLoad(true);
    }
  }, [firstLoad]);
  
  const SetTheData = (data) => {
    console.log(JSON.stringify(data));
    setInitTextContent(data.Content);
    SetCguEdit(data.Content);
  };
  
  const handleChangeContent = (value, editor) => {
    SetCguEdit(value);
  };
  
  const handleSubmit = (event) => {
    
    event.preventDefault();
    
    setLoading(true);
    const body = {Ref: "CGU", Content: cguEdit};
    console.log("submit : "+JSON.stringify(body));
    SaveSetting (body, afterSubmit);
    
  };

  const afterSubmit = () => {
    props.onClose();
  }

  return (<form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <label>Conditions générales d'utilisation</label>
                  <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={initTextContent}
                    tinymceScriptSrc='/scripts/tinymce/tinymce.min.js'
                    init={initConfig}
                    onEditorChange={handleChangeContent}
                  />
                  
              </div>
            </div>
            <div className="text-end mt-3 p-3 border-top">
              <button className="btn btn-primary" type="submit">Valider</button>
            </div>
          </form>);
}

export default SettingCGU;